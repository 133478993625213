import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Book } from '../../foundation/types/Book'
import SEO from '../presentation/components/Seo'
import '../../static/css/_book.scss'
import '../../static/css/_clock.scss'
import { Countdown } from '../presentation/components/Countdown'
import { faAmazon, faAudible } from '@fortawesome/free-brands-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { parse } from 'date-fns'
import { AnalyticsCategory } from '../../foundation/enums/AnalyticsCategory'
import { SocialShare } from '../presentation/components/SocialShare'
import { ButtonLink } from '../presentation/components/ButtonLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook, faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { BuyLinkType } from '../../foundation/enums/BuyLinkType'
import { generateStructuredData } from '../../foundation/helpers/generateStructuredData'
import { getGenreIconName } from '../../foundation/helpers/getGenreIconName'
import { Head } from '../presentation/components/Head'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

const BookTemplate: React.FC<PageProps> = ({ data }) => {
	if (!data) {
		return null
	}

	const queryData = data as any
	const { edges } = queryData.allContentfulBook

	const { siteUrl } = queryData.site.siteMetadata

	const bookNode = edges[0].node as Book

	const releaseDate = bookNode.release_date
		? parse(bookNode.release_date, 'yyyy-MM-dd', new Date())
		: undefined

	const buyAction =
		releaseDate.getTime() / 1000 > new Date().getTime() / 1000
			? 'Pre-order'
			: 'Buy'
	const bookImage = bookNode.cover
		? bookNode.cover.localFile.publicURL
		: '/images/cover.jpg'
	const seoImage = {
		src: bookImage,
		height: 700,
		width: 400,
	}

	const shareMessage =
		buyAction === 'Pre-order'
			? `Countdown to the release of ${bookNode.title} on ${bookNode.release_date} now at Outyet`
			: `${bookNode.title} is available now!`

	return (
		<article className="book-page-root">
			<SEO
				structuredData={generateStructuredData(
					siteUrl,
					bookImage,
					bookNode,
					releaseDate
				)}
				lang="en"
				image={seoImage}
				title={`${bookNode.title}`}
				description={documentToPlainTextString(bookNode.description.json)}
				article="book"
			/>
			<Head title={bookNode.title} />

			<div
				className="container h-100 d-flex w-100"
				style={{ minHeight: '100vh', paddingBottom: '40px' }}
			>
				<div
					className="row h-100 pb-5"
					style={{
						alignItems: 'center',
						alignSelf: 'center',
						justifySelf: 'center',
						alignContent: 'center',
						justifyContent: 'center',
					}}
				>
					<section className="text-center d-block d-md-none col-12 col-md-6"></section>
					<section style={{ color: 'white' }} className="col-12 col-md-6">
						<div className="release-date-container">
							<img
								alt={bookNode.title}
								className="book-page-image d-block d-md-none"
								src={bookImage}
							/>
							<h1 className="book-page-title">{bookNode.title}</h1>
							<div className="py-1">
								{bookNode.author.map(a => (
									<ButtonLink
										key={`author-${a.id}`}
										to={a.link}
										spacing={1}
										text={a.name}
										target="_blank"
										category={AnalyticsCategory.Navigation}
										icon={<FontAwesomeIcon icon={faUser} fixedWidth />}
										color="light"
										outline
									/>
								))}
							</div>
							{bookNode.series && (
								<div className=" py-1 d-block">
									<ButtonLink
										spacing={1}
										category={AnalyticsCategory.Navigation}
										to={bookNode.series.link}
										target="_blank"
										text={
											bookNode.series_index
												? `#${bookNode.series_index!} - ${
														bookNode.series.title
												  }`
												: bookNode.series.title
										}
										outline
										icon={<FontAwesomeIcon icon={faBookOpen} fixedWidth />}
										color="light"
										size="sm"
									/>
								</div>
							)}

							<div className="py-1">
								{bookNode.genres.map(genre => (
									<ButtonLink
										spacing={1}
										key={genre.id}
										className="mr-2"
										target="_blank"
										category={AnalyticsCategory.GenreLink}
										to={`${genre.link}`}
										text={genre.genreName}
										icon={
											<FontAwesomeIcon
												icon={getGenreIconName(genre.icon)}
												fixedWidth
											/>
										}
										color="light"
										size="sm"
										outline
									/>
								))}
							</div>
							<div
								className="book-page-description"
								dangerouslySetInnerHTML={{
									__html: documentToHtmlString(bookNode.description.json),
								}}
							/>

							{releaseDate && (
								<div>
									<Countdown
										to={releaseDate.getTime() / 1000}
										releaseText={bookNode.release_text}
									/>{' '}
								</div>
							)}

							<header className="mt-2">{`${buyAction}:`}</header>
							<div>
								{bookNode.link_ebook && (
									<ButtonLink
										to={bookNode.link_ebook}
										target="_blank"
										type={BuyLinkType.EBook}
										className="text-left"
										size="lg"
										category={AnalyticsCategory.BuyBookLink}
										color="warning"
										icon={<FontAwesomeIcon icon={faAmazon} fixedWidth />}
										text={`${BuyLinkType.EBook}`}
									/>
								)}

								{bookNode.link_audiobook && (
									<ButtonLink
										to={bookNode.link_audiobook}
										target="_blank"
										type={BuyLinkType.Audiobook}
										category={AnalyticsCategory.BuyBookLink}
										className="text-left"
										size="lg"
										color="warning"
										icon={<FontAwesomeIcon icon={faAudible} fixedWidth />}
										text={`${BuyLinkType.Audiobook}`}
									/>
								)}

								{bookNode.link_hardcover && (
									<ButtonLink
										to={bookNode.link_hardcover}
										target="_blank"
										type={BuyLinkType.Hardcover}
										category={AnalyticsCategory.BuyBookLink}
										className="text-left"
										size="lg"
										color="warning"
										icon={<FontAwesomeIcon icon={faBook} fixedWidth />}
										text={`${BuyLinkType.Hardcover}`}
									/>
								)}

								{bookNode.link_paperback && (
									<ButtonLink
										to={bookNode.link_paperback}
										target="_blank"
										type={BuyLinkType.Paperback}
										category={AnalyticsCategory.BuyBookLink}
										className="text-left"
										size="lg"
										color="warning"
										icon={<FontAwesomeIcon icon={faBookOpen} fixedWidth />}
										text={`${BuyLinkType.Paperback}`}
									/>
								)}
							</div>

							<SocialShare
								authors={bookNode.author}
								id={bookNode.slug}
								hashtags={bookNode.hashtags || []}
								text={shareMessage}
							/>
						</div>
					</section>
					<section className="text-center d-none d-md-block col-12 col-md-6">
						<img className="book-page-image" src={bookImage} />
					</section>
				</div>
			</div>
		</article>
	)
}
export default BookTemplate

export const pageQuery = graphql`
	query($id: String!) {
		allContentfulBook(filter: { id: { eq: $id } }) {
			edges {
				node {
					description {
						json
					}
					id
					cover {
						localFile {
							publicURL
						}
					}
					slug
					hashtags
					release_date
					release_text
					title
					genres {
						id
						slug
						link
						genreName
						icon
					}

					author {
						picture {
							localFile {
								publicURL
							}
						}
						id
						name
						link
						social_facebook
						social_twitter
					}
					link_ebook
					link_audiobook
					link_paperback
					link_hardcover
					series {
						title
						slug
						link
						hashtags
					}
					series_index
				}
			}
		}

		site {
			siteMetadata {
				siteUrl: url
			}
		}
	}
`
