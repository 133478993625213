import { IconProp } from '@fortawesome/fontawesome-svg-core'

export function getGenreIconName(name: string): IconProp {
	switch (name) {
		case 'scroll':
			return 'scroll'
		case 'dragon':
			return 'dragon'
		case 'skull-crossbones':
			return 'skull-crossbones'
		case 'robot':
			return 'robot'
		case 'hat-wizard':
			return 'hat-wizard'
		case 'knife-kitchen':
			return 'knife-kitchen'
		case 'user-secret':
			return 'user-secret'
		case 'mask':
			return 'mask'
		case 'space-station-moon':
			return 'space-station-moon'
	}
}
